import {Menu} from "@headlessui/react"
import type {Placement} from "@popperjs/core"
import classNames from "classnames"
import {
  Popover,
  PopoverButtonTransferProps,
  PopoverPanelTransfertProps,
} from "components/commons/popover"
import {Handler, HandlerOf} from "helpers/types/typeHelpers"
import {isArray} from "lodash"
import React, {ReactNode, useEffect, useMemo} from "react"
import {CgCheck} from "react-icons/cg"
import {useRecoilValue} from "recoil"
import {darkModeAtom} from "store/preferences/preferencesData"
import styled, {css} from "styled-components"
import {palette} from "styles/styleHelpers"
import {Button} from "./button"

/*
 * Types.
 */

type WithKey = {key: string | number | boolean}

/*
 * Props.
 */

interface DropdownProps<TItem extends WithKey> {
  className?: string
  button: ReactNode
  items: readonly TItem[]
  renderItem: (
    item: TItem,
    options: {isSelected?: boolean; isActive: boolean},
  ) => ReactNode
  onChange?: HandlerOf<TItem>
  modalClassName?: string
  placement?: Placement
  selectedKeys?: readonly TItem["key"][] | TItem["key"]
}

/*
 * Component.
 */

export function Dropdown<TItem extends WithKey>(props: DropdownProps<TItem>) {
  const {
    className,
    button,
    items,
    modalClassName,
    placement,
    selectedKeys,
    renderItem,
    onChange,
  } = props

  const normalizedSelectedKeys = useMemo((): readonly TItem["key"][] | undefined => {
    if (selectedKeys === undefined) {
      return undefined
    }

    if (isArray(selectedKeys)) {
      return selectedKeys
    }

    return [selectedKeys] as readonly TItem["key"][]
  }, [selectedKeys])

  const renderButton = ({ref}: PopoverButtonTransferProps) => {
    return (
      <Menu.Button as="div" ref={ref} className={className}>
        {button}
      </Menu.Button>
    )
  }

  const renderPanel = ({attributes, ref, styles, update}: PopoverPanelTransfertProps) => {
    return (
      <Menu.Items
        ref={ref}
        className={classNames(
          modalClassName,
          "bg-gray-100 dark:bg-gray-800 rounded shadow py-1",
        )}
        style={styles}
        {...attributes}
      >
        {items.map(item => {
          const isSelected = normalizedSelectedKeys?.includes(item.key)

          return (
            <Menu.Item
              as="div"
              className="cursor-pointer"
              key={getKey(item)}
              onClick={() => {
                onChange?.(item)
              }}
            >
              {({active}) => renderItem(item, {isSelected, isActive: active})}
            </Menu.Item>
          )
        })}
        <DropdownPopperUpdate update={update} />
      </Menu.Items>
    )
  }

  return (
    <Menu>
      <Popover
        placement={placement}
        renderButton={renderButton}
        renderPanel={renderPanel}
      />
    </Menu>
  )
}

/*
 * Props.
 */

interface DropdownPopperUpdateProps {
  update: Handler
  // open: boolean
}

/*
 * Component.
 */

const DropdownPopperUpdate: React.FC<DropdownPopperUpdateProps> = props => {
  const {update} = props

  useEffect(() => {
    update()
  }, [update])

  return <></>
}

/*
 * Props.
 */

interface DropdownButtonProps {
  className?: string
  icon?: ReactNode
  hasBorder?: boolean
}

/*
 * Component.
 */

export const DropdownButton: React.FC<DropdownButtonProps> = props => {
  const {children, className, icon, hasBorder} = props

  return (
    <Button className={className} icon={icon} hasBorder={hasBorder}>
      {children}
    </Button>
  )
}

/*
 * Props.
 */

interface DrodownItemProps {
  className?: string
  icon?: ReactNode
  isSelected?: boolean
  isActive: boolean
}

/*
 * Styles.
 */

const StyledDropdownItem = styled.div<{
  $darkMode: boolean
  $hasIcon: boolean
  $isActive: boolean
}>`
  display: grid;
  width: 100%;

  ${p =>
    p.$hasIcon
      ? css`
          grid-template-areas: "icon content check";
          grid-template-columns: 30px auto max-content;
        `
      : css`
          grid-template-areas: "content check";
          grid-template-columns: auto max-content;
        `}

  align-items: center;

  ${p =>
    p.$isActive
      ? p.$darkMode
        ? "background-color: " + palette.grays["90"] + ";"
        : "background-color: " + palette.grays["10"] + ";"
      : ""}
`

const StyledDropdownItemIcon = styled.div`
  grid-area: icon;
`

const StyledDropdownItemContent = styled.div`
  grid-area: content;
`

const StyledChecked = styled(CgCheck)`
  grid-area: check;
  margin-left: 10px;
`

/*
 * Component.
 */

export const DropdownItem: React.FC<DrodownItemProps> = props => {
  const {children, className, icon, isSelected, isActive} = props

  const hasDarkMode = useRecoilValue(darkModeAtom)

  return (
    <StyledDropdownItem
      className={classNames(className, "px-3 py-1")}
      $darkMode={hasDarkMode}
      $hasIcon={icon !== undefined}
      $isActive={isActive}
    >
      {icon ? <StyledDropdownItemIcon>{icon}</StyledDropdownItemIcon> : null}
      <StyledDropdownItemContent>{children}</StyledDropdownItemContent>
      {isSelected ? <StyledChecked /> : null}
    </StyledDropdownItem>
  )
}

/*
 * Helpers.
 */

function getKey(element: WithKey): string | number {
  if (typeof element.key === "boolean") {
    return String(element.key)
  }

  return element.key
}
