import {makeApiType} from "helpers/fetcher"
import * as t from "io-ts"

const RDeepArtifact = t.type({
  id: t.string,
  name: t.string,
  url: t.string,
})

const RDeepArtifacts = t.readonlyArray(RDeepArtifact)

export interface DeepArtifactModel extends t.TypeOf<typeof RDeepArtifact> {}

function parseArtifact(src: any): DeepArtifactModel {
  return {
    id: src.id,
    name: src.name,
    url: src.url,
  }
}

function parseArtifacts(src: any): DeepArtifactModel[] {
  return (src.artifacts ?? []).map(parseArtifact)
}

export const ApiDeepArtifact = makeApiType("deepArtifact", RDeepArtifact, parseArtifact)
export const ApiDeepArtifacts = makeApiType(
  "deepArtifacts",
  RDeepArtifacts,
  parseArtifacts,
)
