import {useState} from "react"
import {CgClose, CgDarkMode, CgMenu} from "react-icons/cg"
import {Link} from "react-router-dom"
import {useToggleDarkMode} from "store/preferences/preferencesData"
import styled from "styled-components"
import {Container} from "./container"
import {menuLinks} from "./header"

/*
 * Styles.
 */

const StyledMenuContainer = styled(Container)`
  display: grid;
  grid-template-areas:
    "menu close"
    "menu dark-mode"
    "menu .";
  grid-template-columns: auto max-content;
  grid-template-rows: max-content max-content;
  align-items: start;
  grid-gap: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
`

const StyledMenuList = styled.div`
  grid-area: menu;
  display: grid;
  grid-auto-rows: max-content;
`

const StyledCloseButton = styled.button`
  grid-area: close;
`

const StyledDarkModeButton = styled.button`
  grid-area: dark-mode;
`

const StyledLink = styled.a`
  display: grid;
  grid-template-columns: 20px max-content;
  grid-gap: 10px;
  align-items: center;
`

/*
 * Component.
 */

export const MenuMobile: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleDarkMode = useToggleDarkMode()

  const maybeRenderMenu = () => {
    if (!isOpen) {
      return null
    }

    return (
      <div className="fixed top-0 left-0 h-full w-full overflow-y-auto bg-gray-50 dark:bg-gray-900">
        <StyledMenuContainer>
          <StyledMenuList>
            {menuLinks.map(menuLink => (
              <Link
                key={menuLink.key}
                className="text-gray-600 dark:text-gray-400 hover:underline"
                to={menuLink.to}
                onClick={() => setIsOpen(false)}
              >
                <StyledLink>
                  {menuLink.icon}
                  {menuLink.title}
                </StyledLink>
              </Link>
            ))}
          </StyledMenuList>

          <StyledCloseButton className="text-2xl" onClick={() => setIsOpen(false)}>
            <CgClose />
          </StyledCloseButton>
          <StyledDarkModeButton className="text-2xl" onClick={() => toggleDarkMode()}>
            <CgDarkMode />
          </StyledDarkModeButton>
        </StyledMenuContainer>
      </div>
    )
  }

  return (
    <>
      <button className="text-2xl" onClick={() => setIsOpen(true)}>
        <CgMenu />
      </button>
      {maybeRenderMenu()}
    </>
  )
}
