import {Container} from "components/commons/container"
import {DeepArtifactCard} from "components/commons/deepArtifactCard"
import {DeepInteractiveButton} from "components/commons/preferences/deepInteractiveButton"
import {Button} from "components/forms/button"
import {ArtifactDescription} from "components/pages/artifact/artifactDescription"
import {useArtifact} from "fetchers/artifacts"
import {useState} from "react"
import {CgHeart, CgPlayButtonO, CgPlayPauseO} from "react-icons/cg"
import {useParams} from "react-router-dom"
import {useRecoilValue} from "recoil"
import {deepInteractiveDefaultAtom} from "store/preferences/preferencesData"
import styled, {css} from "styled-components"
import {cssMediaSize} from "styles/styleHelpers"

/*
 * Props.
 */

interface ArtifactProps {}

/*
 * Styles.
 */

const StyledArtifactContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-areas: "viewer info";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content;
  /* align-items: self-start; */

  ${cssMediaSize(
    1280,
    css`
      grid-template-areas: "viewer" "info";
      grid-template-columns: 1fr;
      grid-template-rows: min-content min-content;
    `,
  )}
`

const StyledDeepArtifactCard = styled(DeepArtifactCard)`
  grid-area: viewer;
`

const StyledDescription = styled.div`
  grid-area: info;
`

const StyledButtonsActions = styled.div`
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-gap: 10px;
`

const StyledButtonActionsSection = styled.div`
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-gap: 10px;
`

const StyledActionsSeparator = styled.div`
  width: 2px;
  height: 100%;
`

/*
 * Component.
 */

export const Artifact: React.FC<ArtifactProps> = () => {
  const deepInteractiveDefault = useRecoilValue(deepInteractiveDefaultAtom)
  const [isInteractive, setIsInteractive] = useState(deepInteractiveDefault)
  const {artifactId} = useParams()

  const {data: artifact} = useArtifact(String(artifactId))

  const icon = isInteractive ? <CgPlayPauseO /> : <CgPlayButtonO />

  return (
    <Container className="py-4">
      <StyledArtifactContainer>
        <StyledDeepArtifactCard
          hash="0x005165d151f111fz81"
          isInteractive={isInteractive}
          className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-sm"
        />
        <StyledDescription className="xl:h-0 min-h-full flex flex-col">
          <StyledButtonsActions>
            <StyledButtonActionsSection>
              <Button onClick={() => setIsInteractive(!isInteractive)}>{icon}</Button>
              <DeepInteractiveButton />
            </StyledButtonActionsSection>
            <StyledActionsSeparator className="bg-gray-200 dark:bg-gray-700 rounded-full" />
            <StyledButtonActionsSection>
              <Button className="text-red-500">
                <CgHeart />
              </Button>
              <div className="flex items-center font-bold opacity-50">1000 likes</div>
            </StyledButtonActionsSection>
          </StyledButtonsActions>
          <ArtifactDescription artifact={artifact} />
        </StyledDescription>
      </StyledArtifactContainer>
    </Container>
  )
}
