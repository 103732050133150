/*
 * Props.
 */

import classNames from "classnames"

interface ContainerProps {
  className?: string
}

/*
 * Component.
 */

export const Container: React.FC<ContainerProps> = props => {
  const {className, children} = props

  return <div className={classNames("container mx-auto px-8", className)}>{children}</div>
}
