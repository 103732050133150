import {IntProvider} from "helpers/intProvider"
import React from "react"
import ReactDOM from "react-dom"
import {QueryClient, QueryClientProvider} from "react-query"
import {HashRouter} from "react-router-dom"
import {RecoilRoot} from "recoil"
import {App} from "./App"
import "./index.css"
import reportWebVitals from "./reportWebVitals"

const queryClient = new QueryClient()

const Providers: React.FC = ({children}) => (
  <HashRouter>
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <IntProvider>{children}</IntProvider>
      </QueryClientProvider>
    </RecoilRoot>
  </HashRouter>
)

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById("root"),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
