import classNames from "classnames"
import {Footer} from "components/commons/footer"
import {Header} from "components/commons/header"
import {Panel4Provider} from "components/commons/panel4Provider"
import {Router} from "pages/router"
import {useRecoilValue} from "recoil"
import {darkModeAtom} from "store/preferences/preferencesData"
import {createGlobalStyle} from "styled-components"
import {palette} from "styles/styleHelpers"

/*
 * Styles.
 */

const GlobalStyles = createGlobalStyle<{$darkMode: boolean}>`
  html {
    font-family: "Nunito Sans", sans-serif;
    background-color: ${p => (p.$darkMode ? palette.grays[95] : palette.grays[5])};
    color: ${p => (p.$darkMode ? palette.grays[5] : palette.grays[95])}
  }
`

/*
 * Component.
 */

export const App: React.FC = () => {
  const hasDarkMode = useRecoilValue(darkModeAtom)

  return (
    <>
      <GlobalStyles $darkMode={hasDarkMode} />
      <div className={classNames({dark: hasDarkMode})}>
        <Panel4Provider>
          <Header />
          <Router />
          <Footer />
        </Panel4Provider>
      </div>
    </>
  )
}
