import {Route, Routes} from "react-router-dom"
import {Artifact} from "./artifact"
import {Home} from "./home"

/*
 * Component.
 */

export const Router: React.FC = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/artifact/:artifactId" element={<Artifact />} />
  </Routes>
)
