import {DeepArtifactModel} from "models/deepartifact"
import {CgArrowRight} from "react-icons/cg"
import {Link} from "react-router-dom"
import styled from "styled-components"
import {ArtifactRarety} from "./artifactRarety"

/*
 * Props.
 */

interface ArtifactDescriptionProps {
  artifact: DeepArtifactModel | undefined
}

/*
 * Styles.
 */

const StyledH2 = styled.h2`
  font-family: Rubik;
`

const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
`

/*
 * Component.
 */

export const ArtifactDescription: React.FC<ArtifactDescriptionProps> = props => {
  const {artifact} = props

  return (
    <div className="flex flex-col flex-shrink max-h-full min-h-0 pt-4">
      <StyledHeader className="max-h-full">
        <div>
          <div>
            <Link
              to="/collection/toto"
              className="text-sm text-blue-600 dark:text-blue-400 font-bold hover:underline grid grid-flow-col auto-cols-max gap-1 items-center overflow-ellipsis"
            >
              Bored Ape Yacht Club <CgArrowRight />
            </Link>
          </div>
          <StyledH2 className="font-bold text-3xl overflow-ellipsis">
            {artifact?.name}
          </StyledH2>
        </div>
        <button className="grid grid-flow-col auto-cols-max items-center gap-3 rounded-full hover:bg-blue-100 dark:hover:bg-blue-900 px-1 py-1 border-2 border-blue-100 dark:border-blue-900">
          <div className="h-8 w-8 flex items-center justify-center text-lg font-bold rounded-full bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-100">
            I
          </div>
          <div className="mr-2">icanfly3</div>
        </button>
      </StyledHeader>
      <ArtifactRarety className="mt-3" />
    </div>
  )
}
