import {useCallback} from "react"
import {atom, useRecoilState} from "recoil"

const DARK_MODE_KEY = "@@preferences/dark_mode"
const hasDarkMode = getBooleanLocalStorageKey(DARK_MODE_KEY, true)

const DEEP_INTERACTIVE_DEFAULT_KEY = "@@preferences/deep_interactive_default"
const hasDeepInteractiveDefault = getBooleanLocalStorageKey(
  DEEP_INTERACTIVE_DEFAULT_KEY,
  true,
)

const LOCALE_KEY = "@@preferences/locale"
const locale = getStringLocalStorageKey(LOCALE_KEY, "en")

export const darkModeAtom = atom({
  key: "darkMode",
  default: hasDarkMode,
  effects: [
    ({onSet}) => {
      onSet(newValue => {
        localStorage.setItem(DARK_MODE_KEY, String(newValue))
      })
    },
  ],
})

export const deepInteractiveDefaultAtom = atom({
  key: "deepInteractiveDefault",
  default: hasDeepInteractiveDefault,
  effects: [
    ({onSet}) => {
      onSet(newValue => {
        localStorage.setItem(DEEP_INTERACTIVE_DEFAULT_KEY, String(newValue))
      })
    },
  ],
})

export const localeAtom = atom({
  key: "locale",
  default: locale,
  effects: [
    ({onSet}) => {
      onSet(newValue => {
        localStorage.setItem(LOCALE_KEY, newValue)
      })
    },
  ],
})

/*
 * Helpers.
 */

export function useToggleDarkMode() {
  const [hasDarkMode, setHasDarkMode] = useRecoilState(darkModeAtom)

  return useCallback(() => {
    setHasDarkMode(!hasDarkMode)
  }, [hasDarkMode, setHasDarkMode])
}

function getStringLocalStorageKey(key: string, defaultValue: string) {
  const maybeData = localStorage.getItem(key)

  if (maybeData === null) {
    return defaultValue
  }

  return maybeData
}

function getBooleanLocalStorageKey(key: string, defaultValue: boolean) {
  const maybeData = localStorage.getItem(key)

  if (maybeData === null) {
    return defaultValue
  }

  return maybeData === "true"
}
