import {Placement} from "@popperjs/core"
import {Handler, HandlerOf, RendererOf} from "helpers/types/typeHelpers"
import {CSSProperties, useCallback, useMemo, useState} from "react"
import {usePopper} from "react-popper"

/*
 * Consts.
 */

export const popperModifiers = [
  {
    name: "offset",
    options: {
      offset: [0, 8],
    },
  },
  {
    name: "preventOverflow",
    options: {
      altAxis: true,
      padding: 10,
    },
  },
  {
    name: "flip",
    options: {
      padding: 10,
    },
  },
]

/*
 * Types.
 */

type PopperOptions = Parameters<typeof usePopper>[2]

export interface PopoverButtonTransferProps {
  ref: HandlerOf<HTMLElement | null>
}

export interface PopoverPanelTransfertProps {
  ref: HandlerOf<HTMLElement | null>
  update: Handler
  styles: CSSProperties
  attributes: Record<string, string>
}

/*
 * Props.
 */

interface PopoverProps {
  placement?: Placement
  renderButton: RendererOf<PopoverButtonTransferProps>
  renderPanel: RendererOf<PopoverPanelTransfertProps>
}

/*
 * Component.
 */

export const Popover: React.FC<PopoverProps> = props => {
  const {placement = "bottom-start", renderButton, renderPanel} = props

  const [buttonRef, setButtonRef] = useState<HTMLElement | null>(null)
  const [popperRef, setPopperRef] = useState<HTMLElement | null>(null)

  const options = useMemo((): PopperOptions => {
    return {placement, modifiers: popperModifiers}
  }, [placement])

  const {
    attributes,
    styles,
    update: updateOrNull,
  } = usePopper(buttonRef, popperRef, options)

  const update = useCallback(() => {
    if (updateOrNull) {
      updateOrNull()
    }
  }, [updateOrNull])

  return (
    <>
      {renderButton({ref: setButtonRef})}
      {renderPanel({
        ref: setPopperRef,
        update,
        styles: styles.popper,
        attributes: attributes.popper ?? {},
      })}
    </>
  )
}
