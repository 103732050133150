import {Trans} from "@lingui/macro"
import {useIsMobile} from "helpers/hooks/sizeHelpersHooks"
import logo from "images/LogoIcon.png"
import {ReactNode} from "react"
import {CgHome} from "react-icons/cg"
import {Link} from "react-router-dom"
import styled from "styled-components"
import {Container} from "./container"
import {MenuMobile} from "./menuMobile"

/*
 * Types.
 */

export interface MenuLink {
  key: string
  icon?: ReactNode
  title: ReactNode
  to: string
}

/*
 * Consts.
 */

export const menuLinks: readonly MenuLink[] = [
  {key: "home", title: <Trans id="menu.home">Home</Trans>, to: "/", icon: <CgHome />},
]

/*
 * Styles.
 */

const StyledTitle = styled(Link)`
  font-family: "Rubik";
  display: grid;
  grid-template-areas: "logo title";
  grid-gap: 10px;

  > img {
    grid-area: logo;
    display: block;
    max-height: 30px;
  }

  > h1 {
    grid-area: title;
  }
`

const StyledRightContent = styled.div`
  display: grid;
  grid-template-areas: "links";
  grid-gap: 25px;
`

const StyledLinks = styled.div`
  grid-area: links;
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 10px;
`

const StyledLink = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 10px;
  align-items: center;
`

/*
 * Component.
 */

export const Header: React.FC = () => {
  const isMobile = useIsMobile()

  return (
    <header className="border-b border-gray-100 dark:border-gray-800 sticky top-0 bg-gray-50 dark:bg-gray-900 z-10">
      <Container className="h-16 flex items-center justify-between">
        <StyledTitle className="text-xl font-bold" to="/">
          <img src={logo} alt="deepview" />
          <h1>DeepView</h1>
        </StyledTitle>
        {!isMobile ? (
          <StyledRightContent>
            <StyledLinks>
              {menuLinks.map(menuLink => (
                <Link
                  key={menuLink.key}
                  to={menuLink.to}
                  className="text-gray-600 dark:text-gray-400 hover:underline"
                >
                  <StyledLink>
                    {menuLink.icon}
                    {menuLink.title}
                  </StyledLink>
                </Link>
              ))}
            </StyledLinks>
          </StyledRightContent>
        ) : (
          <MenuMobile />
        )}
      </Container>
    </header>
  )
}
