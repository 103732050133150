import classNames from "classnames"
import {useElementSize} from "helpers/hooks/sizeHelpersHooks"
import f1 from "images/layerForest/f1.png"
import f10 from "images/layerForest/f10.png"
import f11 from "images/layerForest/f11.png"
import f4 from "images/layerForest/f4.png"
import f5 from "images/layerForest/f5.png"
import f6 from "images/layerForest/f6.png"
import f7 from "images/layerForest/f7.png"
import f8 from "images/layerForest/f8.png"
import f9 from "images/layerForest/f9.png"
import {lazy, Suspense, useMemo, useState} from "react"
import styled from "styled-components"

const Immersive = lazy(() =>
  import("@deepview/render-react/src/components").then(module => ({
    default: module.Immersive,
  })),
)

/*
 * Props.
 */

interface DeepArtifactCardProps {
  hash: string
  isInteractive?: boolean
  className?: string
}

/*
 * Styles.
 */

const StyledImmersive = styled(Immersive)`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const StyledContainerDiv = styled.div<{$height: string}>`
  height: ${p => p.$height};
`

/*
 * Component.
 */

export const DeepArtifactCard: React.FC<DeepArtifactCardProps> = props => {
  const {isInteractive = true, className} = props
  const [elementRef, setElementRef] = useState<HTMLElement | null>(null)

  const images = useMemo(() => [f1, f4, f5, f6, f7, f8, f9, f10, f11], [])

  const size = useElementSize(elementRef)
  const height = size ? `${Math.trunc(size.width / 2)}px` : "100%"

  return (
    <StyledContainerDiv
      className={classNames(className)}
      $height={height}
      ref={setElementRef}
    >
      {!isInteractive ? (
        <StyledDiv>Inactive</StyledDiv>
      ) : (
        <Suspense fallback={<StyledDiv>Loading...</StyledDiv>}>
          <StyledImmersive images={images} />
        </Suspense>
      )}
    </StyledContainerDiv>
  )
}
