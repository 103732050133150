import classNames from "classnames"
import {forwardRef, MouseEventHandler, ReactNode} from "react"
import styled, {css} from "styled-components"

/*
 * Props.
 */

interface ButtonProps {
  className?: string
  icon?: ReactNode
  transparent?: boolean
  hasBorder?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  children?: ReactNode
}

/*
 * Styles.
 */

const StyledButton = styled.button<{$hasIcon: boolean}>`
  display: grid;
  cursor: pointer;

  ${p =>
    p.$hasIcon
      ? css`
          grid-template-areas: "icon content";
          grid-template-columns: 30px auto;
        `
      : css`
          grid-template-areas: "content";
          grid-template-columns: auto;
        `}

  align-items: center;
`

const StyleButtonContent = styled.div`
  grid-area: content;
`

const StyleButtonIcon = styled.div`
  grid-area: icon;
`

/*
 * Component.
 */

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {children, className, icon, hasBorder, transparent = false, onClick} = props

  return (
    <StyledButton
      ref={ref}
      className={classNames(
        className,
        "hover:bg-gray-300 dark:hover:bg-gray-700 rounded px-3 py-1",
        {
          "border-2 border-gray-400 dark:border-gray-600": hasBorder,
          "bg-gray-200 dark:bg-gray-800": !transparent,
        },
      )}
      $hasIcon={icon !== undefined}
      onClick={onClick}
    >
      {icon ? <StyleButtonIcon>{icon}</StyleButtonIcon> : null}
      <StyleButtonContent>{children}</StyleButtonContent>
    </StyledButton>
  )
})
