import {DeepArtifactModel} from "models/deepartifact"
import {CgArrowUp} from "react-icons/cg"
import {Link} from "react-router-dom"
import styled from "styled-components"

/*
 * Constants
 */

const sizes = {
  small: {
    h: 150,
    w: 300,
  },
  normal: {
    h: 300,
    w: 600,
  },
  large: {
    h: 450,
    w: 900,
  },
} as const

/*
 * Props.
 */

type SizeTypes = keyof typeof sizes

interface DeepCardProps {
  card: DeepArtifactModel
  size?: SizeTypes
}

/*
 * Styles.
 */

const StyledCardContainer = styled.div<{$size: SizeTypes}>`
  height: ${p => sizes[p.$size].h}px;
  width: ${p => sizes[p.$size].w}px;
  overflow: hidden;
  border-radius: 3px;
`

const StyledImage = styled.div<{$imageUrl: string}>`
  background-image: url(${p => p.$imageUrl});
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  transform: scale(1);

  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1);
  }
`

const StyledInformation = styled.div`
  display: grid;
  grid-template-areas: "text box";
  grid-template-columns: auto max-content;
  align-items: end;
  grid-gap: 10px;
`

const StyledText = styled.div`
  grid-area: text;
`

const StyledBox = styled.div`
  grid-area: box;
  background-color: #0000004c;
  display: flex;
  align-items: center;
`

/*
 * Component.
 */

export const DeepCard: React.FC<DeepCardProps> = props => {
  const {card, size = "normal"} = props

  return (
    <Link to="/artifact/abcde">
      <StyledCardContainer
        className="bg-white dark:bg-gray-800 shadow-md relative"
        $size={size}
      >
        <StyledImage $imageUrl={card.url} />
        <StyledInformation className="absolute left-0 bottom-0 w-full px-4 py-4 text-gray-100 text-left">
          <StyledText>
            <div className="opacity-80 text-lg font-bold drop-shadow-md">{card.name}</div>
            <div className="italic drop-shadow-sm">Thomas Gossin</div>
          </StyledText>
          <StyledBox className="text-gray-100 px-4 py-1 rounded text-sm">
            <CgArrowUp /> 360€
          </StyledBox>
        </StyledInformation>
      </StyledCardContainer>
    </Link>
  )
}
