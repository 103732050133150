import {useSize} from "ahooks"

const MOBILE_SIZE = 640

export function useIsMobile() {
  const size = useSize(document.body)

  return size && size.width < MOBILE_SIZE
}

export function useElementSize(element: HTMLElement | null) {
  const size = useSize(element)

  return size
}
