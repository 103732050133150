import {Trans} from "@lingui/macro"
import {Dropdown, DropdownButton, DropdownItem} from "components/forms/dropdown"
import {CgEditMarkup, CgToggleOff, CgToggleOn} from "react-icons/cg"
import {useRecoilState} from "recoil"
import {deepInteractiveDefaultAtom} from "store/preferences/preferencesData"

/*
 * Consts.
 */

const validValues = [
  {
    key: true,
    label: <Trans id="artifact.interactive.default.active">Active</Trans>,
    icon: <CgToggleOn />,
  },
  {
    key: false,
    label: <Trans id="artifact.interactive.default.inactive">Inactive</Trans>,
    icon: <CgToggleOff />,
  },
] as const

/*
 * Props.
 */

interface DeepInteractiveButtonProps {
  className?: string
}

/*
 * Component.
 */

export const DeepInteractiveButton: React.FC<DeepInteractiveButtonProps> = props => {
  const {className} = props
  const [deepInteractiveDefault, setDeepInteractiveDefault] = useRecoilState(
    deepInteractiveDefaultAtom,
  )

  const button = (
    <DropdownButton icon={<CgEditMarkup />}>
      <Trans id="artifact.interactive.default.button">Default</Trans>
    </DropdownButton>
  )

  return (
    <Dropdown
      items={validValues}
      className={className}
      button={button}
      renderItem={(item, props) => (
        <DropdownItem icon={item.icon} {...props}>
          {item.label}
        </DropdownItem>
      )}
      onChange={item => {
        setDeepInteractiveDefault(item.key)
      }}
      selectedKeys={deepInteractiveDefault}
    />
  )
}
