import {i18n} from "@lingui/core"
import {I18nProvider} from "@lingui/react"
import {messages} from "locales/en/messages"
import {useEffect} from "react"
import {useRecoilValue} from "recoil"
import {localeAtom} from "store/preferences/preferencesData"

i18n.load("en", messages)
// i18n.loadLocaleData({
//   en: {},
//   fr: {},
// })
i18n.activate("en")

/*
 * Component.
 */

export const IntProvider: React.FC = props => {
  const {children} = props
  const locale = useRecoilValue(localeAtom)

  useEffect(() => {
    import(`locales/${locale}/messages`)
      .then(({messages: customMessages}) => {
        i18n.load(locale, customMessages)
        i18n.activate(locale)
      })
      .catch(() => {
        i18n.load("en", messages)
        i18n.activate("en")
      })
  }, [locale])

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>
}
