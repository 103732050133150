import classNames from "classnames"

/*
 * Props.
 */

interface TitleProps {
  className?: string
}

/*
 * Styles.
 */

/*
 * Component.
 */

export const Title: React.FC<TitleProps> = props => {
  const {children, className} = props

  return <h2 className={classNames("text-4xl font-black", className)}>{children}</h2>
}
