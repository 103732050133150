import {Button} from "components/forms/button"
import {useIsMobile} from "helpers/hooks/sizeHelpersHooks"
import {Handler, HandlerOf} from "helpers/types/typeHelpers"
import {noop} from "lodash"
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react"
import {CgArrowLeft, CgClose} from "react-icons/cg"
import styled, {css} from "styled-components"
import {cssMobile} from "styles/styleHelpers"

/*
 * Props.
 */

interface PanelP4ProviderProps {}

/*
 * Context.
 */

interface PanelP4Nodes {
  title: ReactNode
  content: ReactNode
}

interface PanelP4ContextState {
  setNodes: HandlerOf<PanelP4Nodes | null>
  setOnClose: HandlerOf<Handler | null>
}

const PanelP4Context = createContext<PanelP4ContextState>({
  setNodes: noop,
  setOnClose: noop,
})

/*
 * Styles.
 */

const StyledContainer = styled.div`
  max-width: 100vw;
  overflow-x: hidden;
`

const cssContainerGridMobile = css<{$isOpen: boolean}>`
  grid-template-columns: ${p => (p.$isOpen ? "0px auto" : "auto 0px")};
  width: 100vw;
`

const StyledContainerGrid = styled.div<{$isOpen: boolean}>`
  display: grid;
  grid-template-areas: "content p4";
  grid-template-columns: auto 340px;

  min-height: 100vh;
  width: calc(100vw + ${p => (p.$isOpen ? "0px" : "340px")});

  transition: width 0.3s;

  ${cssMobile(cssContainerGridMobile)}
`

const StyledContent = styled.div<{$hidden: boolean}>`
  grid-area: content;
  overflow-x: hidden;
  ${p => (p.$hidden ? "display: none;" : "")}
`

const StyledP4 = styled.div`
  grid-area: p4;
  overflow-x: hidden;
  overflow-y: auto;
`

const StyledTitle = styled.div<{$hasBack: boolean}>`
  display: grid;
  align-items: center;
  grid-template-areas: "${p => (p.$hasBack ? "back " : "")}title close";
  grid-template-columns: ${p => (p.$hasBack ? "max-content" : "")} auto max-content;
  grid-gap: 10px;
`

const StyledBack = styled(Button)`
  grid-area: back;
`

const StyledTitleText = styled.div`
  grid-area: title;
`

const StyledClose = styled(Button)`
  grid-area: close;
`

/*
 * Component.
 */

export const Panel4Provider: React.FC<PanelP4ProviderProps> = props => {
  const {children} = props

  const onCloseRef = useRef<Handler | null>(null)
  const [nodes, setNodes] = useState<PanelP4Nodes | null>(null)

  const setOnClose = useCallback(onClose => {
    onCloseRef.current = onClose
  }, [])

  const value = useMemo(
    (): PanelP4ContextState => ({
      setNodes,
      setOnClose,
    }),
    [setOnClose],
  )

  const handleClose = () => {
    onCloseRef.current?.()
  }

  const isOpen = useMemo(() => nodes !== null, [nodes])
  const isMobile = useIsMobile()

  const hasBack = false

  return (
    <PanelP4Context.Provider value={value}>
      <StyledContainer>
        <StyledContainerGrid $isOpen={isOpen}>
          <StyledContent $hidden={isOpen && Boolean(isMobile)}>{children}</StyledContent>
          <StyledP4 className="border-l border-gray-100 dark:border-gray-800">
            <div className="border-b border-gray-100 dark:border-gray-800">
              {nodes ? (
                <>
                  <StyledTitle $hasBack={hasBack} className="h-16 px-4">
                    {hasBack ? (
                      <StyledBack
                        transparent
                        className="h-8 w-8 flex items-center justify-center"
                      >
                        <CgArrowLeft />
                      </StyledBack>
                    ) : null}
                    <StyledTitleText>{nodes.title}</StyledTitleText>
                    <StyledClose
                      className="h-8 w-8 rounded flex items-center justify-center"
                      transparent
                      onClick={handleClose}
                    >
                      <CgClose />
                    </StyledClose>
                  </StyledTitle>
                </>
              ) : null}
            </div>
            {nodes ? <div>{nodes.content}</div> : null}
          </StyledP4>
        </StyledContainerGrid>
      </StyledContainer>
    </PanelP4Context.Provider>
  )
}

/*
 * Hooks.
 */

export function usePanelP4() {
  return useContext(PanelP4Context)
}
