import {ReactNode} from "react"
import styled from "styled-components"

/*
 * Props.
 */

interface ArtifactPropertyProps {
  title: ReactNode
  rarety: number
}

/*
 * Styles.
 */

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
`

/*
 * Component.
 */

export const ArtifactProperty: React.FC<ArtifactPropertyProps> = props => {
  const {rarety, title, children} = props

  return (
    <StyledContainer className="shadow-sm border border-gray-200 dark:border-gray-800 rounded-lg">
      <div className="py-2 px-3">
        <div className="font-bold text-blue-700 dark:text-blue-400 text-xs">{title}</div>
        <div>{children}</div>
      </div>
      <div className="mx-3 px-2 font-bold py-0.5 rounded-lg text-sm text-blue-700 dark:text-blue-100 bg-blue-100 dark:bg-blue-900">
        {rarety}%
      </div>
    </StyledContainer>
  )
}
