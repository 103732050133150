import {useElementSize} from "helpers/hooks/sizeHelpersHooks"
import {ReactNode, useState} from "react"
import {CgArrowLeft, CgArrowRight} from "react-icons/cg"
import styled, {css} from "styled-components"
import {Container} from "./container"

/*
 * Constants.
 */

const sizes = {
  small: {
    gap: 13,
  },
  normal: {
    gap: 26,
  },
}

/*
 * Props.
 */

type SizeTypes = "normal" | "small"

interface CarouselProps {
  shouldRenderContainer?: boolean
  className?: string
  size?: SizeTypes
}

/*
 * Styles.
 */

const StyledCarouselContainer = styled.div`
  position: relative;
`

const StyledCarousel = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    background-color: #ffffff00;
    height: 10px;
    transition: transform 0.3s;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #ffffff00;
    border-radius: 16px;
    border: 4px solid #ffffff00;

    :hover {
      background-color: #ffffff08;
    }
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
`

const cssCarousel = css<{$size: SizeTypes}>`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: ${p => sizes[p.$size].gap}px;
`

const BaseCarouselContainer = styled.div`
  ${cssCarousel}
`

const StyledCourouselContainer = styled(Container)`
  ${cssCarousel}
`

const StyledLastItem = styled.div`
  width: 40px;
`

const carouselScrollCss = css`
  position: absolute;
  bottom: 20px;
  border-radius: 999px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
`

const StyledScrollRight = styled.button`
  ${carouselScrollCss}
  right: 20px;
`

const StyledScrollLeft = styled.button`
  ${carouselScrollCss}
  right: 70px;
`

/*
 * Component
 */

export const Carousel: React.FC<CarouselProps> = props => {
  const {shouldRenderContainer, children, className, size = "normal"} = props

  const [carouselElement, setCarouselElement] = useState<HTMLElement | null>(null)
  const carouselSize = useElementSize(carouselElement)

  const onScroll = (mulitplicator: -1 | 1) => {
    if (!carouselElement || !carouselSize?.width) {
      return
    }

    const carouselScroll = carouselElement.scrollLeft
    const newScrollPosition = carouselScroll + carouselSize.width * 0.75 * mulitplicator

    carouselElement.scrollTo({left: newScrollPosition, behavior: "smooth"})
  }

  const maybeRenderInContainer = (child: ReactNode) => {
    if (shouldRenderContainer) {
      return (
        <StyledCourouselContainer $size={size}>
          {child}
          <StyledLastItem />
        </StyledCourouselContainer>
      )
    }

    return (
      <BaseCarouselContainer $size={size}>
        {child}
        <StyledLastItem />
      </BaseCarouselContainer>
    )
  }

  return (
    <StyledCarouselContainer className={className}>
      <StyledCarousel ref={setCarouselElement}>
        {maybeRenderInContainer(children)}
      </StyledCarousel>
      <StyledScrollRight
        onClick={() => onScroll(1)}
        className="bg-gray-50 dark:bg-gray-900 bg-opacity-40 dark:bg-opacity-40"
      >
        <CgArrowRight />
      </StyledScrollRight>
      <StyledScrollLeft
        onClick={() => onScroll(-1)}
        className="bg-gray-50 dark:bg-gray-900 bg-opacity-40 dark:bg-opacity-40"
      >
        <CgArrowLeft />
      </StyledScrollLeft>
    </StyledCarouselContainer>
  )
}
