import {fetchApi} from "helpers/fetcher"
import {ApiDeepArtifact, ApiDeepArtifacts} from "models/deepartifact"
import {useQuery} from "react-query"

export function useArtifacts() {
  return useQuery(["artifacts"], async () => {
    return fetchApi(ApiDeepArtifacts, "/artifacts")
  })
}

export function useArtifact(id: string) {
  return useQuery(["artifact", {id}], async () => {
    return fetchApi(ApiDeepArtifact, `/artifacts/${id}`)
  })
}
