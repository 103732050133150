import classNames from "classnames"
import {Handler} from "helpers/types/typeHelpers"
import {ReactNode, useEffect} from "react"
import {usePanelP4} from "./panel4Provider"

/*
 * Props.
 */

interface PanelP4Props {
  title: ReactNode
  onClose: Handler
}

/*
 * Component.
 */

export const PanelP4: React.FC<PanelP4Props> = props => {
  const {title, children, onClose} = props

  const {setNodes, setOnClose} = usePanelP4()

  useEffect(() => {
    setOnClose(onClose)
  }, [onClose, setOnClose])

  useEffect(() => {
    setNodes({title, content: children})
  }, [children, setNodes, title])

  useEffect(() => {
    return () => {
      setNodes(null)
    }
  }, [setNodes])

  return <></>
}

/*
 * Props.
 */

interface PanelP4ContentProps {
  className?: string
}

/*
 * Component.
 */

export const PanelP4Content: React.FC<PanelP4ContentProps> = props => {
  const {children, className} = props

  return <div className={classNames(className, "px-4 py-4")}>{children}</div>
}
