import axios from "axios"
import {Mixed, TypeOf} from "io-ts"

const baseApiUrl = process.env.REACT_APP_API_URL ?? "http://localhost:8080"

type ApiParser<TType extends Mixed> = (src: any) => TypeOf<TType>

export class ApiResolver<TType extends Mixed> {
  constructor(
    private resourceName: string,
    private rType: TType,
    private parser: ApiParser<TType>,
  ) {}

  public parseApi(data: any): TypeOf<TType> {
    console.log("data", data)
    const parsedData = this.parser(data)
    console.log(parsedData)
    if (!this.rType.is(parsedData)) {
      throw new Error(`api_parse_error - ${this.resourceName} - Invalid type`)
    }

    return parsedData
  }
}

export function makeApiType<TType extends Mixed>(
  resourceName: string,
  rType: TType,
  parseApi: ApiParser<TType>,
): ApiResolver<TType> {
  return new ApiResolver(resourceName, rType, parseApi)
}

export async function fetchApi<TType extends Mixed>(
  apiType: ApiResolver<TType>,
  url: string,
): Promise<TypeOf<TType>> {
  const {data} = await axios.get(buildApiUrl(url))
  return apiType.parseApi(data)
}

function buildApiUrl(url: string) {
  return `${baseApiUrl}${url}`
}
