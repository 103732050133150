import {Trans} from "@lingui/macro"
import {Dropdown, DropdownButton, DropdownItem} from "components/forms/dropdown"
import {CgDarkMode, CgFlag, CgMoon, CgSun} from "react-icons/cg"
import {useRecoilState} from "recoil"
import {darkModeAtom, localeAtom} from "store/preferences/preferencesData"
import styled from "styled-components"
import {Container} from "./container"

/*
 * Consts.
 */

const themeItems = [
  {
    key: "light",
    name: <Trans id="footer.theme.light_mode">Light mode</Trans>,
    icon: <CgSun />,
  },
  {
    key: "dark",
    name: <Trans id="footer.theme.dark_mode">Dark mode</Trans>,
    icon: <CgMoon />,
  },
] as const

const languageItems = [
  {key: "en", item: <Trans id="footer.lang.en">English</Trans>},
  {key: "fr", item: <Trans id="footer.lang.fr">French</Trans>},
] as const

/*
 * Styles.
 */

const StyledContainer = styled(Container)`
  display: grid;
  grid-template-areas: "content options";
  align-items: top;
  grid-template-columns: auto max-content;
`

const StyledContent = styled.div`
  grid-area: content;
`

const StyledOptions = styled.div`
  grid-area: options;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 10px;
  align-items: stretch;
`

/*
 * Component.
 */

export const Footer: React.FC = () => {
  const [, setLocale] = useRecoilState(localeAtom)
  const [, setDarkMode] = useRecoilState(darkModeAtom)

  return (
    <footer className="border-t border-gray-100 dark:border-gray-800 py-16">
      <StyledContainer>
        <StyledContent>Immersive</StyledContent>
        <StyledOptions>
          <Dropdown
            button={
              <DropdownButton icon={<CgFlag />} className="h-full">
                <Trans id="footer.language">Language</Trans>
              </DropdownButton>
            }
            items={languageItems}
            renderItem={(item, props) => (
              <DropdownItem {...props}>{item.item}</DropdownItem>
            )}
            className="h-full"
            onChange={item => {
              setLocale(item.key)
            }}
          />
          <Dropdown
            button={
              <DropdownButton icon={<CgDarkMode />} hasBorder>
                <Trans id="footer.theme">Theme</Trans>
              </DropdownButton>
            }
            items={themeItems}
            renderItem={(item, props) => (
              <DropdownItem icon={item.icon} {...props}>
                {item.name}
              </DropdownItem>
            )}
            modalClassName="right-0 bottom-0"
            onChange={item => {
              setDarkMode(item.key === "dark")
            }}
          />
        </StyledOptions>
      </StyledContainer>
    </footer>
  )
}
