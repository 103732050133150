import {Trans} from "@lingui/macro"
import {Carousel} from "components/commons/carousel"
import {Container} from "components/commons/container"
import {DeepCard} from "components/commons/deepCard"
import {Title} from "components/commons/title"

/*
 * Component.
 */

export const Home: React.FC = () => {
  return (
    <>
      <Container className="mt-10">
        <Title>
          <Trans id="home.starred">Starred</Trans>
        </Title>
      </Container>
      <Carousel className="my-8" shouldRenderContainer size="normal">
        <DeepCard
          size="large"
          card={{
            id: "dqsdq",
            url: "https://wallpaperaccess.com/full/6836716.png",
            name: "Toto",
          }}
        />
        <DeepCard
          size="large"
          card={{
            id: "dqsdq",
            url: "https://wallpaperaccess.com/full/6836716.png",
            name: "Toto",
          }}
        />
        <DeepCard
          size="large"
          card={{
            id: "dqsdq",
            url: "https://wallpaperaccess.com/full/6836716.png",
            name: "Toto",
          }}
        />
      </Carousel>

      <Container className="mt-10">
        <Title>
          <Trans id="home.populars">Populars</Trans>
        </Title>
      </Container>
      <Carousel className="my-8" shouldRenderContainer size="small">
        <DeepCard
          card={{
            id: "dqsdq",
            url: "https://wallpaperaccess.com/full/6836716.png",
            name: "Toto",
          }}
          size="small"
        />
        <DeepCard
          card={{
            id: "dqsdq",
            url: "https://wallpaperaccess.com/full/6836716.png",
            name: "Toto",
          }}
          size="small"
        />
        <DeepCard
          card={{
            id: "dqsdq",
            url: "https://wallpaperaccess.com/full/6836716.png",
            name: "Toto",
          }}
          size="small"
        />
        <DeepCard
          card={{
            id: "dqsdq",
            url: "https://wallpaperaccess.com/full/6836716.png",
            name: "Toto",
          }}
          size="small"
        />
        <DeepCard
          card={{
            id: "dqsdq",
            url: "https://wallpaperaccess.com/full/6836716.png",
            name: "Toto",
          }}
          size="small"
        />
        <DeepCard
          card={{
            id: "dqsdq",
            url: "https://wallpaperaccess.com/full/6836716.png",
            name: "Toto",
          }}
          size="small"
        />
      </Carousel>

      <Container className="mt-10">
        <Title>
          <Trans id="home.liked">Liked</Trans>
        </Title>
      </Container>
      <Carousel className="my-8" shouldRenderContainer size="small">
        <DeepCard
          card={{
            id: "dqsdq",
            url: "https://wallpaperaccess.com/full/6836716.png",
            name: "Toto",
          }}
          size="small"
        />
        <DeepCard
          card={{
            id: "dqsdq",
            url: "https://wallpaperaccess.com/full/6836716.png",
            name: "Toto",
          }}
          size="small"
        />
        <DeepCard
          card={{
            id: "dqsdq",
            url: "https://wallpaperaccess.com/full/6836716.png",
            name: "Toto",
          }}
          size="small"
        />
        <DeepCard
          card={{
            id: "dqsdq",
            url: "https://wallpaperaccess.com/full/6836716.png",
            name: "Toto",
          }}
          size="small"
        />
        <DeepCard
          card={{
            id: "dqsdq",
            url: "https://wallpaperaccess.com/full/6836716.png",
            name: "Toto",
          }}
          size="small"
        />
        <DeepCard
          card={{
            id: "dqsdq",
            url: "https://wallpaperaccess.com/full/6836716.png",
            name: "Toto",
          }}
          size="small"
        />
      </Carousel>
    </>
  )
}
