import {css, FlattenInterpolation, ThemeProps} from "styled-components"

export const palette = {
  grays: {
    100: "#000000",
    95: "rgb(17, 24, 39)",
    90: "rgb(55, 65, 81)",
    10: "rgb(229, 231, 235)",
    5: "rgb(249, 250, 251)",
    0: "#ffffff",
  },
} as const

export function cssMediaSize<T extends ThemeProps<any>>(
  maxSize: number,
  styles: FlattenInterpolation<T>,
): FlattenInterpolation<T> {
  return css<T>`
    @media (max-width: ${maxSize}px) {
      ${styles}
    }
  `
}

export function cssMobile<T extends ThemeProps<any>>(
  styles: FlattenInterpolation<T>,
): FlattenInterpolation<T> {
  return cssMediaSize(680, styles)
}
