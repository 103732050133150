import {Trans} from "@lingui/macro"
import {PanelP4, PanelP4Content} from "components/commons/panelP4"
import {Button} from "components/forms/button"
import {useState} from "react"
import styled from "styled-components"
import {ArtifactProperty} from "./artifactProperty"

/*
 * Props.
 */

interface ArtifactRaretyProps {
  className?: string
}

/*
 * Styles.
 */

const StyledContainer = styled.div`
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-gap: 5px;
`

const StyledP4Content = styled(PanelP4Content)`
  display: grid;
  grid-template-columns: auto;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-gap: 10px;
`

/*
 * Component.
 */

export const ArtifactRarety: React.FC<ArtifactRaretyProps> = props => {
  const {className} = props

  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={className}>
      <div className="text-blue-600 dark:text-blue-400 font-bold text-sm">
        <Trans id="artifact.title.properties">Properties</Trans>
      </div>
      <StyledContainer className="mt-2">
        <div className="bg-gray-200 dark:bg-gray-800 rounded shadow-sm px-3 py-1">
          Cat
        </div>
        <div className="bg-gray-200 dark:bg-gray-800 rounded shadow-sm px-3 py-1">
          Nails
        </div>
        <div className="bg-gray-200 dark:bg-gray-800 rounded shadow-sm px-3 py-1">
          Hair
        </div>
        <Button
          className="shadow-sm opacity-50 hover:opacity-100"
          onClick={() => setIsOpen(!isOpen)}
        >
          +23
        </Button>
        {isOpen ? (
          <PanelP4 title="Rarety" onClose={() => setIsOpen(false)}>
            <StyledP4Content>
              <ArtifactProperty title="Hat" rarety={20}>
                Blue
              </ArtifactProperty>
              <ArtifactProperty title="Hat" rarety={20}>
                Blue
              </ArtifactProperty>
              <ArtifactProperty title="Hat" rarety={20}>
                Blue
              </ArtifactProperty>
              <ArtifactProperty title="Hat" rarety={20}>
                Blue
              </ArtifactProperty>
            </StyledP4Content>
          </PanelP4>
        ) : null}
      </StyledContainer>
    </div>
  )
}
